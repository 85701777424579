import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';



const GreenRadio = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '90%',
        margin: 'auto'

    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,

    },
}));


export default function EditorSelect({ with1 }) {
    const [selectedValue, setSelectedValue] = React.useState('Back');
    // const [ind, setInd] = React.useState(false);
    const classes = useStyles();



    const handleChange = (event) => {
        event.preventDefault()
        setSelectedValue(event.target.value);
        if (event.target.value === 'Back') {
            with1(false)



        }
        else {
            with1(true)

        }
    };

    return (


        <div className={classes.root}>
            <RadioGroup row aria-label="position" name="position" defaultValue='Back'>
                <p>Back</p>
                <GreenRadio
                    checked={selectedValue === 'Back'}
                    onChange={handleChange}
                    value="Back"
                    name="radio-button-demo"
                    inputProps={{ 'aria-label': 'C' }}
                    label="Back"
                />
                <p>Front</p>

                <Radio
                    checked={selectedValue === 'Front'}
                    onChange={handleChange}
                    value="Front"
                    color="default"
                    name="radio-button-demo"
                    inputProps={{ 'aria-label': 'D' }}
                    label="Front"
                />
            </RadioGroup>
        </div>
    )
}
