import { GET_BACK } from "../actions/types.js"
import { CLEAR_BACK } from "../actions/types.js"

import { ADD_BACK } from "../actions/types.js"
import { DELETE_BACK } from "../actions/types.js"
import { PUT_BACK } from "../actions/types.js"


const initialState = {
    backs: [],
    loaded: false,
    canPutBack: false,
    lastBackPayload: '',
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_BACK:

            return {
                ...state,
                backs: action.payload,
                loaded: true
            };
        case CLEAR_BACK:
            return {
                ...state,
                backs: [],
            };
        case ADD_BACK:
            return {
                ...state,
                backs: [...state.backs, action.payload],
                canPutBack: true,
                lastBackPayload: action.payload
            };
        case DELETE_BACK:
            return {
                ...state,
                backs: state.backs.filter((back) => back.id1 !== action.payload),
            };
        case PUT_BACK:
            return {
                ...state,
                backs: [...state.backs.filter((back) => back.id1 !== action.payload), action.data],
                canPutBack: false

            };
        default:

            return state;
    }
}