import React, { } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import programming from "./components/subcomponents/img/programming.png"
import Social from "./components/Social"
import Fixed from "./components/Fixed"
import {
    Link
} from "react-router-dom";
import { connect } from "react-redux"
import { getFronts, deleteFront } from "./actions/front"
import ProductSection from "./components/subcomponents/ProductSection"
import WorkSection from "./components/subcomponents/WorkSection"
import ToHTML from './components/ToHTML';
import "./FrontPage.css"



const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '95%',
        margin: 'auto'

    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,

    },
}));


export function FrontPage(props) {
    const classes = useStyles();

    const deleteItem = async (p) => (
        props.deleteFront(p.id1)
    )

    return (
        <>
            <Fixed />

            <div className={classes.root}>
                <Grid container style={{ alignItems: 'stretch' }} spacing={1}>
                    <Grid item xs={4}>
                        <Paper elevation={5}>
                            <img id='progimg' src={programming} style={{ width: '100%', height: '100%' }} alt="progimg" />
                        </Paper>
                    </Grid>
                    <Grid item xs={8} id='three'>
                        <Paper elevation={5} style={{ height: '100%', width: '100%' }}>
                            <ProductSection />
                        </Paper>
                    </Grid>

                    {props.fronts.map((p => (
                        <Grid item key={p.id1} className="ttt2">
                            <div style={{ position: 'relative' }}>
                                {/* <Paper elevation={5} style={mainStyle.paperBlog} > */}
                                <Paper elevation={5} className='ttt1' >

                                    <div style={{ fontSize: '36px', textAlign: 'right', marginBottom: "2vh" }}>{p.title}</div>
                                    <div dir='rtl' style={{ display: 'relative', margin: 'auto' }}>
                                        {/* <img src={p.file1} style={{ margin: '10px', maxHeight: '300px', maxWidth: '300px', float: 'left' }} alt={p.id1} /> */}
                                        <img src={p.file1} style={{ display: 'relative', marginBottom: "2vh", maxHeight: '25vh', maxWidth: '50vw' }} alt={p.id1} />


                                        <ToHTML edit={true} id={p.id1} Post={p.Content} />


                                        {props.auth.isAuthenticated ?
                                            <div style={{ position: 'absolute', top: '5px', left: '5px' }}>
                                                <button onClick={e => { e.preventDefault(); deleteItem(p) }}>x</button>
                                                <Link to={"/frontlist/" + p.id1}>
                                                    <button>
                                                        A
                                                        </button>
                                                </Link>

                                            </div> : <div></div>}

                                    </div>

                                </Paper>
                            </div>
                        </Grid>
                    )))}

                    <Grid item xs={12}  >
                        <Paper elevation={5} style={{ padding: '50px' }}  >
                            <WorkSection />
                        </Paper>
                    </Grid>

                    <Grid item xs={12} >
                        {/* <Paper elevation={0} style={{ margin: "20px 0px" }}> */}
                        <Social />
                        {/* </Paper> */}
                    </Grid>
                </Grid>
            </div >
        </>
    );
}

const mapStateToProps = state => ({
    fronts: state.front.fronts,
    auth: state.auth,
    loaded: state.front.loaded
})

export default connect(mapStateToProps, { getFronts, deleteFront })(FrontPage)



const mainStyle = {
    paperBlog: {
        padding: '50px 50px 20px 50px',
        overflow: 'hidden',
        height: '35vh',
        maxHeight: '44vh',
        borderBottom: '6vh solid transparent',
        textOverflow: 'ellipsis',

    }
    ,
    more: {
        position: 'absolute',
        top: '90%',
        left: '5%',
        textAlign: 'left'
    }

}
