import React, { } from 'react'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Fixed from "./components/Fixed"



const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '90%',
        margin: 'auto'

    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,

    },
}));


export default function NoPermission() {
    const classes = useStyles();

    return (
        <>
            <Fixed />
            <div className={classes.root}>
                <Grid container style={{ alignItems: 'stretch' }} spacing={1}>
                    <Grid item xs={12} >
                        <Paper elevation={0} style={{ margin: "20px 0px" }}>
                            <h1>You don't have permission for this page!</h1>
                        </Paper>
                    </Grid>

                </Grid>
            </div >
        </>
    );
}


