import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Fixed from './Fixed'
import { putFront } from "../actions/front"
import { connect } from "react-redux"
import ToHTML from "./ToHTML"
import { Helmet } from "react-helmet";


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 0,
        width: '95%',
        margin: 'auto'

    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,

    },
}));

export function ViewPost({ x, putFront, frontOrBack, Post, loaded, edit, id, setLoad }) {
    const classes = useStyles();

    const [toolbar, changeToolbar] = useState(true)

    return (
        <>
            < Fixed />

            <div className={classes.root}>
                <Grid container style={{ alignItems: 'stretch' }} spacing={1}>
                    <Grid item xs={12} style={{ paddingTop: '0px', marginTop: '0px' }}>
                        <Paper elevation={5} style={mainStyle.paperBlog} >
                            <ToHTML x={x} toolbar1={toolbar} changeToolbar={changeToolbar} loaded={loaded} edit={edit} id={id} Post={Post.Content} />
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    canPutFront: state.front.canPutFront,
    canPutBack: state.back.canPutBack,
    lastBackPayload: state.back.lastBackPayload

});

export default connect(mapStateToProps, { putFront })(ViewPost);



const mainStyle = {
    paperBlog: {
        padding: '0px 50px'
        // overflow: 'hidden',
        // borderBottom: '6vh solid transparent',
        // textOverflow: 'ellipsis'
    }
    ,
    more: {
        position: 'absolute',
        top: '90%',
        left: '5%',
        textAlign: 'left'
    }
}