import React, { useEffect } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import FrontPage from './FrontPage'
import Compose from './Compose'
import PostList from "./components/PostList"
import ViewPost from "./components/ViewPost"
import Loading from "./components/Loading"
import store from "./store"
import Login from "./accounts/Login"
import Logout from "./accounts/Logout"
import Register from "./accounts/Register"
import { loadUser } from "./actions/auth"
import { getBacks } from "./actions/back"
import { getFronts } from "./actions/front"
import { Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import { connect } from 'react-redux';
import Alerts from "./Alerts"
import NoPermission from "./NoPermission"
import { Helmet } from "react-helmet";


export function App(props) {




    useEffect(() => {
        store.dispatch(loadUser())
        store.dispatch(getBacks())
        store.dispatch(getFronts())


    }, [])


    const alertOptions = {
        timeout: 3000,
        position: 'middle',
    };

    return (
        <>
            <Helmet>
                <title>Turbo Todo</title>
                <meta name="description" content="Todos!" />
                <meta name="theme-color" content="#008f68" />
            </Helmet>
            <AlertProvider template={AlertTemplate} {...alertOptions}>
                <Alerts />
                <Router>

                    <Switch>

                        <Route exact path="/">
                            {props.loaded === true ? <FrontPage /> : <Loading color="red" />}
                        </Route>
                        <Route path="/compose">
                            {props.auth.isAuthenticated === true ? <Compose /> : <NoPermission />}
                        </Route>
                        <Route exact path="/postlist">
                            {props.loaded === true ? <PostList posts={props.backs} /> : <Loading color='yellow' />}
                        </Route>

                        <Route exact path="/Login" component={Login} />
                        <Route exact path="/Register" component={Register} />
                        <Route exact path="/Logout" component={Logout} />

                        {props.backs.map((s) => (
                            <Route key={s.id1} path={"/postlist/" + s.id1} >
                                <Helmet>
                                    <title>My Title</title>
                                    <meta name="description" content="Helmet application" />
                                </Helmet>
                                <ViewPost id={s.id1} x={'back'} Post={s} />

                            </Route>
                        ))}
                        {props.fronts.map((s) => (
                            <Route key={s.id1} path={"/frontlist/" + s.id1} >
                                <ViewPost id={s.id1} x={'front'} Post={s} />

                            </Route>
                        ))}

                    </Switch>
                </Router >
            </AlertProvider>
        </>
    )

}

const mapStateToProps = (state) => ({
    auth: state.auth,
    loaded: state.front.loaded,
    backs: state.back.backs,
    fronts: state.front.fronts,
    canPutBack: state.back.canPutBack,
    canPutFront: state.front.canPutFront


});

export default connect(mapStateToProps)(App);