import React, { Component } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import uuid from 'react-uuid'
import Button from '@material-ui/core/Button';
import EditorSelect from "./EditorSelect"
import { connect } from 'react-redux';
import { addBack } from "../actions/back"
import { addFront } from "../actions/front"
import { putFront } from "../actions/front"
import { putBack } from "../actions/back"



class MainEditor extends Component {

    constructor(props) {
        super(props);
        const html = this.props.content || ''
        const idd = uuid()


        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        this.state = {
            editorState,
            id: idd,
            uploadedImages: [],
            dictImage: {


            },
            fileUploadState: '',
            with1: false,
            imgCount: 0

        }
    }

    onEditorStateChange: Function = (editorState) => {
        this.setState({
            editorState,
        });
        this.arr = draftToHtml(convertToRaw(editorState.getCurrentContent()))

    };


    async uploadImageCallBack(file) {

        this.state.imgCount = this.state.imgCount + 1
        this.state.dictImage[this.state.imgCount] = {
            file: file,
            src: URL.createObjectURL(file)

        }

        return new Promise(
            (resolve, reject) => {
                resolve({
                    data: {
                        link: this.state.dictImage[this.state.imgCount].src

                    }
                });

            }
        );




    }
    onChangeFile(event) {
        event.stopPropagation();
        event.preventDefault();
        var file = event.target.files[0];
        console.log(file);
        this.setState({ fileUploadState: file }); /// if you want to upload latter
    }


    render() {


        const { editorState } = this.state;

        let dict2 = {}


        const changeWith = (x) => {
            this.setState({ ...this.state, with1: x })

        }
        const handleClick = async (e) => {

            e.preventDefault()


            let imgs = ['file1', 'file2', 'file3', 'file4', 'file5']
            var fileR = new FormData();
            let count = 0
            let tempDic = {
                id1: this.state.id
            }
            fileR.append('id1', this.state.id)
            fileR.append('title', e.target.elements[2].value)

            fileR.append('Content', this.arr)
            fileR.append('file3', this.state.fileUploadState)

            for (let key in this.state.dictImage) {

                if (this.arr.includes(this.state.dictImage[key].src)) {
                    if (count < 5) {
                        fileR.append(imgs[count], this.state.dictImage[key].file)
                        dict2[this.state.dictImage[key].src] = imgs[count]
                        count = count + 1


                    }


                }

            }




            if (!this.state.with1) {


                await this.props.addBack(fileR)

                setInterval(() => {

                    for (let key in this.state.dictImage) {

                        this.arr = this.arr.replace(this.state.dictImage[key].src, this.props.lastBackPayload[dict2[this.state.dictImage[key].src]])

                    }
                    tempDic['Content'] = this.arr

                }, 2000);




                setInterval(() => {


                    if (this.props.canPutBack) {
                        this.props.putBack(this.state.id, tempDic)
                    }
                }, 2000);


            }
            if (this.state.with1) {


                await this.props.addFront(fileR)


                this.arr = this.arr.replace(/<img[^>]*>/g, "")



                tempDic['Content'] = this.arr


                setInterval(() => {

                    if (this.props.canPutFront) {
                        this.props.putFront(this.state.id, tempDic)
                    }
                }, 200);





            }


        }
        const display = () => {
            if (this.state.fileUploadState !== "") {
                return <img style={{ height: '20vh' }} src={URL.createObjectURL(this.state.fileUploadState)} />
            }

        }

        return (
            <>
                <div style={{ display: 'flex', flexDirection: 'column', width: '10vw', margin: 'auto' }}>
                    {
                        display()
                    }
                    <input id="myInput"
                        type="file"
                        ref={(ref) => this.upload = ref}
                        style={{ display: 'none' }}
                        onChange={this.onChangeFile.bind(this)}
                    />

                    <button
                        onClick={() => { this.upload.click() }}

                    >Upload title image</button>
                </div>

                {/* {<img src={this.state.fileUploadState} />} */}
                <div style={{ display: 'flex', flexDirection: 'column' }}>


                    <form onSubmit=
                        {
                            e => handleClick(e)
                        } style={{ margin: 'auto', display: 'flex', width: '80%', flexDirection: 'column', alignItems: 'center' }}>
                        <EditorSelect with1={changeWith} />

                        <div style={{ display: 'flex' }}>

                            <input className='title' style={{ height: '20px', width: '500px', alignSelf: 'flex-end', marginRight: '50px', margin: 'auto' }}></input>
                            <p style={{ justifyContent: 'space-around' }}>   :العنوان</p>



                        </div>
                        <div style={{ display: 'flex' }}>

                            <input className='description' style={{ height: '20px', width: '500px', alignSelf: 'flex-end', marginRight: '50px', margin: 'auto' }}></input>
                            <p style={{ justifyContent: 'space-around' }}>   :شرح</p>
                        </div>

                        <Editor
                            toolbar={{
                                textAlignment: { inDropdown: true },
                                image: {
                                    uploadCallback: this.uploadImageCallBack.bind(this),
                                }

                            }}
                            editorClassName="editor-class"
                            editorState={editorState}
                            editorStyle={{ margin: 'auto', height: '300px', width: '100%' }}
                            toolbarStyle={{ margin: 'auto', marginTop: '20px', borderTop: 'solid black 1px', borderBottom: 'solid black 1px', width: '100%' }}
                            onEditorStateChange={this.onEditorStateChange}

                        />

                        <Button type="submit" variant="contained" color="primary">
                            Submit
      </Button>
                    </form>


                </div >
            </>
        );
    }
}



const mapStateToProps = (state) => ({
    auth: state.auth,
    canPutFront: state.front.canPutFront,
    canPutBack: state.back.canPutBack,
    lastBackPayload: state.back.lastBackPayload

});

export default connect(mapStateToProps, { putFront, addFront, addBack, putBack })(MainEditor);


