import React, { } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import {

    Link
} from "react-router-dom";
import { connect } from "react-redux"
import bayanatLogo from "./subcomponents/img/bayanatlogo.png"







const useStyles = makeStyles(theme => ({
    root: {
        margin: 'auto',
        // padding: '0px',
    }

}));



export function Bar(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default" style={mainStyle.mainBar}>
                {props.isAuthenticated ?
                    <>
                        <Link to="/" >
                            <img style={{ height: '3vh', marginTop: "5px" }} src={bayanatLogo} />

                        </Link>

                        {/* </Link> */}

                        <div style={mainStyle.BarTextLeft}>
                            {/* <Link style={mainStyle.left} to="/">
                                الصفحة الرئيسية
                </Link> */}



                        </div >
                        <div style={mainStyle.BarTextLeft}>
                            <div style={mainStyle.center} >
                                {`Hi  ${props.user.username}`}
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <Link to="/" >
                            <img style={{ height: '3vh', marginTop: "5px" }} src={bayanatLogo} />

                        </Link>
                        <div style={mainStyle.BarTextLeft}>


                        </div>
                    </>
                }

                {props.isAuthenticated ?
                    <>

                        <Link style={{ ...mainStyle.BarText, margin: '0px 0px 0px 0px' }} to="/logout">
                            تسجيل خروج
                </Link>
                        <Link style={mainStyle.BarText} to="/compose">
                            أضف مقالة
                    </Link>

                        <Link style={mainStyle.BarText} to="/postlist">
                            مقالات
                    </Link>

                    </>
                    :
                    <>
                        <Link style={mainStyle.BarText} to="/postlist">
                            مقالات
                        </Link>
                        <Link style={mainStyle.BarText} to="/login">
                            تسجيل دخول
                                    </Link></>}


            </AppBar>


        </div >
    );
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    loaded: state.front.loaded,
    backs: state.back.backs,
    fronts: state.front.fronts,
    canPutBack: state.back.canPutBack,
    canPutFront: state.front.canPutFront,
    user: state.auth.user


});

export default connect(mapStateToProps)(Bar);

const mainStyle = {
    mainBar: {

        borderBottom: 'solid black 3px',
        backgroundColor: 'white',
        padding: '20px 20px',
        height: '70px',
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'flex-end',
        alignItems: 'center',
        color: 'white',

    },
    subBar: {
        borderBottom: 'solid white 10px',
        backgroundColor: 'white',
        height: '30px',
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'flex-end',
        alignItems: 'center',
        color: 'white',

    },
    BarText: {
        margin: '0px 0px 0px 50px',
        color: 'black',
        textDecoration: 'none'
    },
    BarTextLeft: {
        color: 'black',
        margin: '0px 0px 0px 0px',

        marginRight: 'auto',

        textDecoration: 'none'
    },

    left: {
        color: 'black',
        margin: "0px 0px 0px 0px",
        paddingLeft: "10px",
        paddingRight: "10px",

        marginRight: 'auto',

        textDecoration: 'none'

    },

    center: {
        color: 'black',
        margin: 'auto',


        textDecoration: 'none'
    }


}

