import axios from 'axios';
import { createMessage, returnErrors } from './messages';
import { tokenConfig } from './auth';

import { GET_BACK } from './types.js';
import { ADD_BACK } from './types.js';
import { DELETE_BACK } from './types.js';

import { PUT_BACK } from './types.js';



// GET LEADS
export const getBacks = () => (dispatch, getState) => {
    axios.get('https://www.bayanatstore.com/blog', tokenConfig(getState)).then((res) => {
        dispatch({
            type: GET_BACK,
            payload: res.data,
        });
    }).catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));


};

export const addBack = (Back) => (dispatch, getState) => {
    axios
        .post('https://www.bayanatstore.com/blog/', Back, tokenConfig(getState))
        .then((res) => {
            dispatch(createMessage({ post: 'Wait To Confirm' }));

            dispatch({
                type: ADD_BACK,
                payload: res.data,
            });
        })
        .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};


// DELETE LEAD
export const deleteBack = (id) => (dispatch, getState) => {
    axios
        .delete(`https://www.bayanatstore.com/blog/${id}/`, tokenConfig(getState))
        .then((res) => {

            dispatch(createMessage({ deleteItem: 'Item Deleted' }));
            dispatch({
                type: DELETE_BACK,
                payload: id,
            });
        })
        .catch((err) => console.log(err));
};


export const putBack = (id, content) => (dispatch, getState) => {
    axios
        .put(`https://www.bayanatstore.com/blog/${id}/`, content, tokenConfig(getState))
        .then((res) => {
            dispatch(createMessage({ post: 'Confirmed' }));
            dispatch({
                type: PUT_BACK,
                payload: id,
                data: res.data
            });
        })
        .catch((err) => console.log(err));
};

// // ADD LEAD
// export const addLead = (lead) => (dispatch, getState) => {
//   axios
//     .post('/api/leads/', lead, tokenConfig(getState))
//     .then((res) => {
//       dispatch(createMessage({ addLead: 'Lead Added' }));
//       dispatch({
//         type: ADD_LEAD,
//         payload: res.data,
//       });
//     })
//     .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
// };