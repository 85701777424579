import React, { } from 'react'
import {
    Link
} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Fixed from "./Fixed"
import { connect } from "react-redux"
import { deleteBack } from "../actions/back"



const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '95%',
        margin: 'auto'

    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,

    },
}));

export function PostList(props) {


    const deleteItem = async (e) => {
        e.preventDefault()
        props.deleteBack(e.target.elements[0].className)
    }

    const classes = useStyles();

    return (
        <>
            <Fixed />

            <div className={classes.root}>
                <Grid item xs={12} style={{ paddingTop: '0px', padding: '0px', marginTop: '0px' }}>
                    <Paper elevation={5} style={mainStyle.paperBlog} >
                        {
                            props.posts.map((s) => (
                                <form onSubmit={deleteItem}>
                                    <Link to={"/postlist/" + s.id1} style={{ textDecoration: 'none' }}>

                                        <Grid item style={{
                                            flexGrow: 0,
                                            maxWidth: "50%",
                                            flexBasis: "50%",
                                        }}>
                                            <Paper elevation={5} style={{ marginTop: "1vh", height: '20vh', textDecoration: 'none', display: 'flex', alignItems: 'flex-start' }} >
                                                <img style={{ padding: '1vh', height: '18vh', float: 'left' }} src={s.file3}></img>
                                                <div style={{ flex: '2', flexDirection: 'column' }}>
                                                    <h3 dir='rtl' style={{ padding: '1vh 2vh' }}>{s.title}</h3>
                                                    <p dir='rtl' style={{ paddingRight: '2vh' }} dangerouslySetInnerHTML={{ __html: s.Content.slice(0, 30) }} />
                                                </div>

                                                {/* <button >Front Page</button> */}
                                            </Paper>

                                        </Grid>
                                    </Link>
                                    {props.auth.isAuthenticated ? <button type="submit" className={s.id1}>x</button> : null}


                                </form>
                            ))
                        }
                    </Paper>
                </Grid>


            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { deleteBack })(PostList);

const mainStyle = {
    paperBlog: {
        padding: '1vh 1vw',
        margin: 'auto',
        // overflow: 'hidden',
        // borderBottom: '6vh solid transparent',
        // textOverflow: 'ellipsis',
        minHeight: '50vh'
    }
    ,
    more: {
        position: 'absolute',
        top: '90%',
        left: '5%',
        textAlign: 'left'
    }
}