import React, { } from 'react'
import Editor1 from "./components/MainEditor"
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Fixed from "./components/Fixed"
import { connect } from "react-redux"


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '95%',
        margin: 'auto'

    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,

    },
}));

export function Compose(props) {
    const classes = useStyles();



    return (
        <>
            <Fixed />
            <div className={classes.root}>
                <Grid container style={{ alignItems: 'stretch' }} spacing={1}>
                    <Grid item xs={12} >
                        <Paper elevation={0} style={{ margin: "20px 0px" }}>
                            <Editor1 />
                        </Paper>
                    </Grid>
                </Grid>
            </div >
        </>
    );
}


const mapStateToProps = (state) => ({
    auth: state.auth,
    loaded: state.front.loaded,
    backs: state.back.backs,
    fronts: state.front.fronts,
    canPutBack: state.back.canPutBack,
    canPutFront: state.front.canPutFront


});

export default connect(mapStateToProps)(Compose);
