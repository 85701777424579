export const GET_FRONT = "GET_FRONT"

export const GET_ERRORS = 'GET_ERRORS';
export const CREATE_MESSAGE = 'CREATE_MESSAGE';
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const CLEAR_FRONT = 'CLEAR_FRONT';
export const ADD_FRONT = 'ADD_FRONT';

export const DELETE_FRONT = 'DELETE_FRONT';
export const PUT_FRONT = 'PUT_FRONT';


export const CLEAR_BACK = 'CLEAR_BACK';
export const ADD_BACK = 'ADD_BACK';

export const DELETE_BACK = 'DELETE_BACK';

export const GET_BACK = "GET_BACK"
export const PUT_BACK = "PUT_BACK"
