import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from "../actions/auth"

export class Logout extends Component {

    componentDidMount = () => {
        this.props.logout()

    }



    render() {
        if (!this.props.isAuthenticated) {
            return <Redirect to="/" />;
        }
        return (
            <h1>
                You are logged out
            </h1>
        );
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { logout })(Logout);