import React, { Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import Button from '@material-ui/core/Button';
import Loading from "./Loading"
import { putFront } from "../actions/front"
import { putBack } from "../actions/back"
import { connect } from "react-redux"




class ToHTML extends Component {
    constructor(props) {
        super(props);
        const contentBlock = htmlToDraft(this.props.Post);

        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);

        const editorState = EditorState.createWithContent(contentState);

        this.state = {
            editorState,
            id: this.props.id,
            load: true

        };
    }

    onEditorStateChange: Function = (editorState) => {
        this.setState({
            editorState,
        });
        this.arr = draftToHtml(convertToRaw(editorState.getCurrentContent()))

    };

    render() {
        let tempDic = {}
        const SubmitHandling = (e) => {
            e.preventDefault()
            tempDic['Content'] = this.arr
            tempDic['id1'] = this.props.id
            if (this.props.x === 'front') {
                this.props.putFront(this.props.id, tempDic)

            }
            else {
                this.props.putBack(this.props.id, tempDic)

            }


        }
        const { editorState } = this.state;
        const clickk = () => (
            this.props.changeToolbar(!this.props.toolbar1)
        )


        return (
            <>{this.state.load ?
                <div>
                    {this.props.edit ?
                        <div style={{ fontSize: '1' }}>
                            <div dir='rtl' dangerouslySetInnerHTML={{ __html: this.props.Post }} />
                        </div> :
                        <><Editor
                            toolbar={{
                                textAlignment: { inDropdown: true },
                            }}
                            editorClassName="editor-class"
                            editorState={editorState}
                            readOnly={this.props.toolbar1}
                            toolbarHidden={this.props.toolbar1}
                            editorStyle={{ margin: 'auto', width: '100%' }}
                            toolbarStyle={{ margin: 'auto', marginTop: '20px', borderTop: 'solid black 1px', borderBottom: 'solid black 1px', width: '100%' }}
                            onEditorStateChange={this.onEditorStateChange}

                        />
                            {this.props.auth.isAuthenticated ? <>
                                <Button onClick={SubmitHandling} type="submit" variant="contained" color="primary">
                                    Submit
                                </Button>
                                {this.props.toolbar1 ? <><button onClick={clickk} >Edit</button></> : <button onClick={clickk} >Confirm</button>}


                            </> : null}
                        </>

                    }
                </div> :
                <Loading color="gray" />}
            </>
        );
    }
}


const mapStateToProps = (state) => ({
    auth: state.auth,
    canPutFront: state.front.canPutFront,
    canPutBack: state.back.canPutBack,
    lastBackPayload: state.back.lastBackPayload

});

export default connect(mapStateToProps, { putFront, putBack })(ToHTML);

