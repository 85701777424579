import axios from 'axios';
import { createMessage, returnErrors } from './messages';
import { tokenConfig } from './auth';

import { GET_FRONT } from './types.js';
import { ADD_FRONT } from './types.js';
import { DELETE_FRONT } from './types.js';
import { PUT_FRONT } from './types.js';


// GET LEADS
export const getFronts = () => (dispatch, getState) => {
    axios.get('https://www.bayanatstore.com/front', tokenConfig(getState)).then((res) => {
        dispatch({
            type: GET_FRONT,
            payload: res.data,
        });
    }).catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));


};

export const addFront = (Front) => (dispatch, getState) => {
    axios
        .post('https://www.bayanatstore.com/front/', Front, tokenConfig(getState))
        .then((res) => {
            dispatch(createMessage({ post: 'Waiting for confirmation!' }));
            dispatch({
                type: ADD_FRONT,
                payload: res.data,
            });
        })
        .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};


// DELETE LEAD
export const deleteFront = (id) => (dispatch, getState) => {
    axios
        .delete(`https://www.bayanatstore.com/front/${id}/`, tokenConfig(getState))
        .then((res) => {
            dispatch(createMessage({ deleteItem: 'Item Deleted' }));
            dispatch({
                type: DELETE_FRONT,
                payload: id,
            });
        })
        .catch((err) => console.log(err));
};

export const putFront = (id, content) => (dispatch, getState) => {
    axios
        .put(`https://www.bayanatstore.com/front/${id}/`, content, tokenConfig(getState))
        .then((res) => {
            dispatch(createMessage({ post: 'Confirmed' }));
            dispatch({
                type: PUT_FRONT,
                payload: id,
                data: res.data
            });
        })
        .catch((err) => console.log(err));
};


// // ADD LEAD
// export const addLead = (lead) => (dispatch, getState) => {
//   axios
//     .post('/api/leads/', lead, tokenConfig(getState))
//     .then((res) => {
//       dispatch(createMessage({ addLead: 'Lead Added' }));
//       dispatch({
//         type: ADD_LEAD,
//         payload: res.data,
//       });
//     })
//     .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
// };