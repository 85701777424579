import { combineReducers } from 'redux';
import front from './front';
import auth from "./auth"
import errors from './errors';
import back from './back';
import messages from './messages';

export default combineReducers({
    front,
    auth,
    errors,
    back,
    messages,
});