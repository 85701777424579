import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../actions/auth';
import Fixed from "../components/Fixed"
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

export class Login extends Component {
    state = {
        username: '',
        password: '',
    };

    static propTypes = {
        login: PropTypes.func.isRequired,
        isAuthenticated: PropTypes.bool,
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.props.login(this.state.username, this.state.password);
    };

    onChange = (e) => this.setState({ [e.target.name]: e.target.value });

    render() {
        if (this.props.isAuthenticated) {
            return <Redirect to="/" />;
        }
        const { username, password } = this.state;
        return (
            <div >
                <Fixed />
                <form onSubmit={this.onSubmit}>

                    <div style={{ display: "flex", flexDirection: 'column', width: '25%', margin: 'auto', backgroundColor: 'lightblue', border: '1px black solid', borderRadius: '5px', padding: '75px 50px', }}>
                        <h1 style={{ marginBottom: '15px', marginTop: '20px', textAlign: 'center' }}>Login</h1>

                        <TextField id="Username" label="Username"
                            name="username"
                            onChange={this.onChange}
                            value={username}
                            style={{ marginBottom: '15px' }} />
                        <br />
                        <TextField id="Password" label="Password"
                            type="password"

                            name="password"
                            onChange={this.onChange}
                            value={password}
                            style={{ marginBottom: '15px' }} />
                        <Button type="submit">Sumbit</Button>


                        <p>
                            Don't have an account? <Link to="/register">Register</Link>
                        </p>

                    </div>
                </form>

            </div >
        );
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(Login);