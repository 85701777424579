import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { register } from '../actions/auth';
import { createMessage } from '../actions/messages';
import Fixed from "../components/Fixed"
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

export class Register extends Component {
    state = {
        username: '',
        email: '',
        password: '',
        password2: '',
    };

    static propTypes = {
        register: PropTypes.func.isRequired,
        isAuthenticated: PropTypes.bool,
    };

    onSubmit = (e) => {
        e.preventDefault();
        const { username, email, password, password2 } = this.state;
        if (password !== password2) {
            this.props.createMessage({ passwordNotMatch: 'Passwords do not match' });
        } else {
            const newUser = {
                username,
                password,
                email,
            };
            this.props.register(newUser);
        }
    };

    onChange = (e) => this.setState({ [e.target.name]: e.target.value });

    render() {
        if (this.props.isAuthenticated) {
            return <Redirect to="/" />;
        }
        const { username, email, password, password2 } = this.state;
        return (
            <div >
                <Fixed />
                <form onSubmit={this.onSubmit}>

                    <div style={{ display: "flex", flexDirection: 'column', width: '25%', margin: 'auto', backgroundColor: 'lightblue', border: '1px black solid', borderRadius: '5px', padding: '75px 50px', }}>
                        <h1 style={{ marginBottom: '15px', marginTop: '20px', textAlign: 'center' }}>Login</h1>

                        <TextField id="Username" label="Username"
                            name="username"
                            onChange={this.onChange}
                            value={username}
                            style={{ marginBottom: '15px' }} />
                        <br />
                        <TextField id="email" label="email"
                            type="email"

                            name="email"
                            onChange={this.onChange}
                            value={email}
                            style={{ marginBottom: '15px' }} />

                        <TextField id="Password" label="Password"
                            type="password"

                            name="password"
                            onChange={this.onChange}
                            value={password}
                            style={{ marginBottom: '15px' }} />
                        <TextField id="Confirm Password" label="Confirm Password"
                            type="password"

                            name="password2"
                            onChange={this.onChange}
                            value={password2}
                            style={{ marginBottom: '15px' }} />
                        <Button type="submit">Sumbit</Button>


                    </div>
                </form>

            </div >
            // <div className="col-md-6 m-auto">
            //     <div className="card card-body mt-5">
            //         <h2 className="text-center">Register</h2>
            //         <form onSubmit={this.onSubmit}>
            //             <div className="form-group">
            //                 <label>Username</label>
            //                 <input
            //                     type="text"
            //                     className="form-control"
            //                     name="username"
            //                     onChange={this.onChange}
            //                     value={username}
            //                 />
            //             </div>
            //             <div className="form-group">
            //                 <label>Email</label>
            //                 <input
            //                     type="email"
            //                     className="form-control"
            //                     name="email"
            //                     onChange={this.onChange}
            //                     value={email}
            //                 />
            //             </div>
            //             <div className="form-group">
            //                 <label>Password</label>
            //                 <input
            //                     type="password"
            //                     className="form-control"
            //                     name="password"
            //                     onChange={this.onChange}
            //                     value={password}
            //                 />
            //             </div>
            //             <div className="form-group">
            //                 <label>Confirm Password</label>
            //                 <input
            //                     type="password"
            //                     className="form-control"
            //                     name="password2"
            //                     onChange={this.onChange}
            //                     value={password2}
            //                 />
            //             </div>
            //             <div className="form-group">
            //                 <button type="submit" className="btn btn-primary">
            //                     Register
            //   </button>
            //             </div>
            //             <p>
            //                 Already have an account? <Link to="/login">Login</Link>
            //             </p>
            //         </form>
            //     </div>
            // </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { register, createMessage })(Register);