import React from 'react'
import Bar from "./Bar"
import hijab from "./subcomponents/img/blue.jpg"
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';




const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '95%',
        margin: 'auto'

    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,

    },
}));


export default function Fixed({ loaded }) {

    const classes = useStyles();

    return (
        <div className={classes.root}>

            <Grid container style={{ alignItems: 'stretch' }} spacing={1}>
                <Grid item xs={12} style={{ paddingBottom: '0px', marginTop: '0px' }} >
                    <Bar style={{ padding: '0px', margin: '0px', width: '100%' }} loaded={loaded} />

                </Grid>
                <Grid item xs={12} style={{ paddingTop: '0px', marginTop: '0px' }}>
                    <img src={hijab} style={{ height: '40vh', width: '100%' }} alt='main-img' />

                </Grid>


            </Grid>
        </div>
    )
}
