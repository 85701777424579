import { GET_FRONT } from "../actions/types.js"
import { CLEAR_FRONT } from "../actions/types.js"

import { ADD_FRONT } from "../actions/types.js"
import { DELETE_FRONT } from "../actions/types.js"
import { PUT_FRONT } from "../actions/types.js"


const initialState = {
    fronts: [],
    loaded: false,
    canPutFront: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_FRONT:
            return {
                ...state,
                fronts: action.payload,
                loaded: true
            };
        case CLEAR_FRONT:
            return {
                ...state,
                fronts: [],
            };
        case ADD_FRONT:
            return {
                ...state,
                fronts: [...state.fronts, action.payload],
                canPutFront: true
            };
        case DELETE_FRONT:
            return {
                ...state,
                fronts: state.fronts.filter((front) => front.id1 !== action.payload),
            };
        case PUT_FRONT:
            return {
                ...state,
                fronts: [...state.fronts.filter((front) => front.id1 !== action.payload), action.data],
                canPutFront: false

            };

        default:
            return state;
    }
}