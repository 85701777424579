import React from 'react'
import instagram from "./subcomponents/img/instagram.png"
import twitter from "./subcomponents/img/twitter.png"
import facebook from "./subcomponents/img/facebook.png"
import youtube from "./subcomponents/img/youtube.png"

export default function Social() {
    return (
        <div style={{ margin: 'auto' }}>
            <div style={mainStyle.title}>يشرفنا دعمكم وتواصلكم معنا</div>
            <div style={mainStyle.socialFlex}>
                <div style={mainStyle.social} >
                    <div >
                        YouTube
                </div>
                    <img src={youtube} style={mainStyle.img} alt="youtube-logo" />
                </div>
                <div style={mainStyle.social} >
                    <div>
                        Twitter
                </div>
                    <img src={twitter} style={mainStyle.img} alt="twitter-logo" />
                </div>
                <div style={mainStyle.social} >
                    <div>
                        Facebook
                </div>
                    <img src={facebook} style={mainStyle.img} alt="facebook-logo" />
                </div>
                <div style={mainStyle.social} >
                    <div>
                        Instagram
                </div>
                    <img src={instagram} style={mainStyle.img} alt="instrgram-logo" />
                </div>





            </div>
        </div>
    )
}

const mainStyle = {
    social: {
        display: 'flex',
        flexDirection: 'column',
        height: '15vh',
        justifyContent: 'space-around',
        alignItems: 'center'
    },
    title: {
        textAlign: 'center',
        padding: '20px 10px'
    },
    socialFlex: {
        textAlign: 'center',

        display: 'flex',
        padding: '50px 10px',
        justifyContent: 'space-between',
        width: '50vw',
        margin: 'auto'
    },
    img: {
        height: '7vh',
        width: '7vh'
    }
}
